// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DossierTypeBlockLayoutByViewSlug(
    $viewSlug: String!,
    $dossierId: ID!,
    $dossierTypeSlug: String!,
    $navigationSlug: String!,
  ) {
    dossierDetailInformation: dossierTypeBlockLayoutByViewSlug(
      slug: $viewSlug,
      dossierId: $dossierId,
      dossierTypeSlug: $dossierTypeSlug,
      navigationSlug: $navigationSlug,
    ) {
      blocks {
        id
        name
        location
        label
      }
    }
  }
`;

export default QUERY;
